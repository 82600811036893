import { getSearchlist } from "./directus.js";
import autoComplete from "@tarekraafat/autocomplete.js";
export const autoCompleteJS = new autoComplete({
  data: {
    src: async () => {
      try {
        // Loading placeholder text
        document
          .getElementById("autoComplete")
          .setAttribute("placeholder", "Search...");
        // Fetch External Data Source
        const data = await getSearchlist();
        document
          .getElementById("autoComplete")
          .setAttribute("placeholder", autoCompleteJS.placeHolder);
        return data;
      } catch (error) {
        return error;
      }
    },
    keys: ["botanical_name", "disease", "family"],
    cache: true,
  },
  placeHolder: "Search for plant & formulations",
  resultsList: {
    element: (list, data) => {
      const info = document.createElement("p");
      if (data.results.length > 0) {
        info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results`;
      } else {
        info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
      }
      list.prepend(info);
    },
    noResults: true,
    maxResults: 10,
    tabSelect: true,
  },
  resultItem: {
    element: (item, data) => {
      // Modify Results Item Style
      item.style = "display: flex; justify-content: space-between;";
      // Modify Results Item Content
      item.innerHTML = `
      <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        ${data.match}
      </span>
      <span style="display: flex; align-items: center; font-size: 13px; font-weight: 400; text-transform: uppercase; color:var(--primary) ;">
        ${data.key}
      </span>`;
    },
    highlight: true,
  },
  events: {
    input: {
      focus: () => {
        if (autoCompleteJS.input.value.length) autoCompleteJS.start();
      },
      selection(event) {
        const feedback = event.detail;
        autoCompleteJS.input.blur();
        // Prepare User's Selected Value
        const selection = feedback.selection.value[feedback.selection.key];
        // Replace Input value with the selected value
        autoCompleteJS.input.value = selection;
        // Console log autoComplete data feedback
        //console.log(feedback);
        //window.location = "/search/?Query=" + selection;
        let query_array = feedback.selection.value;
        let stype = feedback.selection.key;
        let squery = query_array[stype];
        if (stype === "botanical_name") {
          window.location = "/search#/results?q=&bname=" + squery;
        } else if (stype === "disease") {
          window.location = "/search#/results?q=&disease=" + squery;
        } else if (stype == "family") {
          window.location = "/search#/results?q=&bfamily=" + squery;
        }
      },
    },
  },
});
