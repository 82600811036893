import { Directus } from "@directus/sdk";

// Directus Initialization
// ---
let BASE_URL = "https://admin.audadham.in";
let API_TOKEN = "l8y_Gb3TIc3pzvLuaZKkuOSvyd72mPxx";

const directus = new Directus(BASE_URL);
await directus.auth.static(API_TOKEN);

// Get list of items for the searchbar
// ---
async function getSearchlist() {
  let plant_family_filler = [];
  const plant_family_response = await directus
    .items("plants")
    .readByQuery({ sort: ["id"], fields: ["plant_family"], limit: -1 })
    .then((data) =>
      data.data.forEach((item) => plant_family_filler.push(item.plant_family))
    )
    .then((data) =>
      plant_family_filler.filter(
        (item, index) => plant_family_filler.indexOf(item) == index
      )
    );

  let plants_filler = [];
  const plants_response = await directus
    .items("plants")
    .readByQuery({ sort: ["id"], fields: ["botanical_name"], limit: -1 })
    .then((data) =>
      data.data.forEach((item) => plants_filler.push(item.botanical_name))
    )
    .then((data) =>
      plants_filler.filter(
        (item, index) => plants_filler.indexOf(item) == index
      )
    );

  let diseases_filler = [];
  const diseases_response = await directus
    .items("medicinal_formulations")
    .readByQuery({ sort: ["id"], fields: ["diseases_treated"], limit: -1 })
    .then((data) =>
      data.data.forEach((item) => diseases_filler.push(item.diseases_treated))
    )
    .then((data) => diseases_filler.flat(1).filter(Boolean))
    .then((data) => data.filter((item, index) => data.indexOf(item) == index));

  let searchList = [];
  for (let plant of await plants_response) {
    searchList.push({ botanical_name: plant });
  }

  for (let disease of await diseases_response) {
    searchList.push({ disease: disease });
  }

  for (let family of await plant_family_response) {
    searchList.push({ family: family });
  }
  return searchList;
}
async function getPlantName(formulation_uuid) {
  const formulation_name = await directus.items("plants").readByQuery({
    filter: { id: { _eq: formulation_uuid } },
    fields: ["botanical_name"],
    limit: 1,
  });
  return formulation_name.data[0]["botanical_name"];
}

async function getFormulation(e_id) {
  const formulation = await directus
    .items("medicinal_formulations")
    .readByQuery({
      filter: { id: { _eq: e_id } },
      fields: [
        "formulation_name",
        "formulation_uuid",
        "formulation_state",
        "parts_used",
        "tribe_name",
        "tribe_locality",
      ],
      limit: 1,
    });
  return formulation;
}

async function getPlants(bname = "", disease = "", bfamily = "") {
  if (bname !== "") {
    const splants = await directus.items("plants").readByQuery({
      sort: ["id"],
      filter: { botanical_name: { _eq: bname } },
      fields: [
        "botanical_name",
        "plant_family",
        "plant_description",
        "data_created_by",
        "date_entered_on",
      ],
      limit: -1,
    });
    //.then((data) => data.data.forEach((item) => item));

    return splants;
  } else if (bfamily !== "") {
    const splants = await directus.items("plants").readByQuery({
      sort: ["id"],
      filter: { plant_family: { _eq: bfamily } },
      fields: [
        "botanical_name",
        "plant_family",
        "plant_description",
        "data_created_by",
        "date_entered_on",
      ],
      limit: -1,
    });
    return splants;
  } else if (disease !== "") {
    let formulation_id_list = [];
    const all_formulations = await directus
      .items("medicinal_formulations")
      .readByQuery({
        sort: ["id"],

        //filter: { diseases_treated: { _contains: '"' + disease + '"' } },
        fields: [
          "id",
          "formulation_uuid",
          "tribe_name",
          "tribe_locality",
          "formulation_name",
          "formulation_state",
          "diseases_treated",
          "parts_used",
        ],
        limit: -1,
      });
    //Filter formulation by diseases
    all_formulations["data"].forEach((element) => {
      if (element.diseases_treated.includes(disease)) {
        formulation_id_list.push(element.id);
      }
    });
    const diseases_result = [];
    // Get Data for each formulation
    async function get_formulations(formulation_id_list) {
      for (const formulation_id of formulation_id_list) {
        let formulation = await getFormulation(formulation_id);
        let plant_name = await getPlantName(
          formulation.data[0]["formulation_uuid"]
        );
        formulation.data[0]["botanical_name"] = plant_name;
        diseases_result.push(formulation.data[0]);
      }
    }
    await get_formulations(formulation_id_list);
    //console.log(diseases_result);
    return diseases_result;
  }
}
export { getSearchlist, directus, getPlants, getFormulation };
